/** @jsx jsx */
import React from 'react'
import { Styled, jsx } from 'theme-ui'
//
import Header from './header'

const Layout = ({ children, location, headerStyle = null, minimal = false, ...rest }) => {
  const [showMenu, setShowMenu] = React.useState(false)

  if (minimal) {
    return children
  }

  return (
    <Styled.root sx={{ height: '90vh' }} {...rest}>
      <div>
        <Header
          siteTitle="Cristina Susano"
          headerStyle={headerStyle}
          handleToggleMenu={toggle => setShowMenu(toggle)}
          showMenu={showMenu}
          location={location}
        />
      </div>

      <main sx={{ p: 3, height: '100%' }}>{children}</main>
    </Styled.root>
  )
}

export default Layout
