/** @jsx jsx */
import React from 'react'
import { useColorMode, Styled, IconButton, jsx } from 'theme-ui'
import { Link } from 'gatsby'
//
import { DarkModeIcon, LightModeIcon } from './icons'

const Header = ({ siteTitle, location, headerStyle = null }) => {
  const [colorMode, setColorMode] = useColorMode()
  const [textColor, setTextColor] = React.useState('white')
  const { pathname: activePath } = location

  React.useEffect(() => {
    setTextColor(headerStyle ? headerStyle.toLowerCase() : colorMode === 'default' ? 'text' : 'white')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!headerStyle) {
      setTextColor(colorMode === 'default' ? 'text' : 'white')
    } else {
      setTextColor(headerStyle ? headerStyle.toLowerCase() : colorMode === 'default' ? 'text' : 'white')
    }
  }, [headerStyle, colorMode])

  const MenuLink = ({ to, children }) => (
    <Link
      to={to}
      sx={{
        color: textColor,
        textTransform: 'uppercase',
        p: [1, 2, 2],
        textDecoration: 'none',
        fontSize: ['.725rem', '.925rem', '.925rem'],
        fontWeight: activePath === to ? 700 : 500
      }}
    >
      {children}
    </Link>
  )

  return (
    <Styled.div
      as="header"
      sx={{
        marginBottom: [0, 1, 1]
      }}
    >
      <div
        sx={{
          mx: 'auto',
          px: 3,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Styled.h1 sx={{ fontSize: ['1rem', '1.5rem', '2rem'] }}>
          <Link
            to="/"
            sx={{
              color: textColor,
              textDecoration: 'none'
            }}
          >
            {siteTitle}
          </Link>
        </Styled.h1>

        <div sx={{ mx: 'auto' }} />

        <MenuLink to="/works">Works</MenuLink>

        <MenuLink to="/shop">Shop</MenuLink>

        <MenuLink to="/about">About</MenuLink>

        {activePath !== '/' ? (
          <IconButton
            sx={{ cursor: 'pointer' }}
            onClick={e => {
              setColorMode(colorMode === 'default' ? 'dark' : 'default')
            }}
          >
            {colorMode === 'default' ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
        ) : null}
      </div>
    </Styled.div>
  )
}

export default Header
